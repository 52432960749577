import {Box, Divider, Flex, Text, useColorMode, useColorModeValue, VStack} from "@chakra-ui/react";
import React from "react";
import {DesktopSidebarNavItem} from "./DesktopSidebarNavItem";
import {LayoutProps} from "@chakra-ui/styled-system";
import {
  HOW_IT_WORKS,
  MY_CLAIMS,
  RECENT_CLAIMS,
  TAG_BRAINSTORM_VALUE,
  TAG_DECIDED_VALUE,
  TAG_NEEDS_INFO_VALUE,
  TAG_PROPOSAL_VALUE,
  TAG_REVISIT_VALUE
} from "../../../model/constants";
import {safeFindSidebarNavItem} from "../dashboard_view_utils";

/**
 * A React component that renders the Dashboard's Sidebar Navigation Menu when the app is in "Desktop" viewing mode.
 * @constructor
 */
export const DesktopSidebar = (props: LayoutProps) => {

  const {colorMode} = useColorMode()
  const isDarkMode = colorMode === "dark";

  return (
    <Box
      {...props}
    >
      <Flex
        position={"sticky"}
        left={"0"}
        h={"100vh"}
        boxShadow={useColorModeValue("gray.50", "gray.600")}
        w={"200px"}
        flexDir={"column"}
      >
        <VStack
          spacing={0}
          pt={3}
          alignItems={"flex-start"}
          width={"100%"}
        >
          <DesktopSidebarNavItem navItem={safeFindSidebarNavItem(MY_CLAIMS, isDarkMode)}/>
          <DesktopSidebarNavItem navItem={safeFindSidebarNavItem(RECENT_CLAIMS, isDarkMode)}/>

          <Divider mt={4} mb={5} borderColor={useColorModeValue("gray.300", "gray.600")}/>

          <Text
            pt={0}
            pl={3}
            pb={3}
            color={useColorModeValue("gray.600", "gray.400")}
          >Action Items</Text>

          <DesktopSidebarNavItem navItem={safeFindSidebarNavItem(TAG_BRAINSTORM_VALUE, isDarkMode)}/>
          <DesktopSidebarNavItem navItem={safeFindSidebarNavItem(TAG_NEEDS_INFO_VALUE, isDarkMode)}/>
          <DesktopSidebarNavItem navItem={safeFindSidebarNavItem(TAG_PROPOSAL_VALUE, isDarkMode)}/>
          <DesktopSidebarNavItem navItem={safeFindSidebarNavItem(TAG_DECIDED_VALUE, isDarkMode)}/>
          <DesktopSidebarNavItem navItem={safeFindSidebarNavItem(TAG_REVISIT_VALUE, isDarkMode)}/>

          <Divider mt={4} mb={5} borderColor={useColorModeValue("gray.300", "gray.600")}/>

          <Text pt={0} pl={3} pb={3} color={useColorModeValue("gray.600", "gray.400")}>More Info</Text>

          <DesktopSidebarNavItem navItem={safeFindSidebarNavItem(HOW_IT_WORKS, isDarkMode)}/>

        </VStack>

      </Flex>
    </Box>
  );
}