import React, {PropsWithChildren} from "react";
import {Box, Center, Flex} from "@chakra-ui/react";
import {Sidebar} from "../../components/Dashboard/Sidebar/Sidebar";

/**
 * Holds the dashboard, which has a sidebar and a viewable content area.
 * @param props A {@link PropsWithChildren}. Note that if we want to supply custom props to this view, we would need to
 * type this as a `PropsWithChildren<DashboardViewProps>`.
 * @constructor
 */
export const DashboardView = (props: PropsWithChildren) => {
  return (
    <Center>
      <Flex
        className={"parent"}
        position={"sticky"}
        flexDir={{base: "column", md: "row", lg: "row"}}
      >
        <Sidebar/>
        <Box pl={{base: 0, md: 4, lg: 4}} w={"100%"}>
          {props.children}
        </Box>
      </Flex>
    </Center>
  );
};