import {Box} from "@chakra-ui/react";
import React from "react";
import {DesktopSidebar} from "./DesktopSidebar";
import {MobileSidebar} from "./MobileSidebar";

/**
 * A React component that renders the Dashboard's Sidebar Navigation Menu (supports both mobile and desktop view modes).
 * @constructor
 */
export const Sidebar = () => {
  return (
    <Box>
      <DesktopSidebar display={{base: "none", md: "flex", lg: "flex"}}/>
      <Box pt={3} pb={0} pl={0} pr={3}>
        <MobileSidebar display={{base: "flex", md: "none", lg: "none"}}/>
      </Box>
    </Box>
  );
}


