import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Center,
  Container,
  Divider,
  HStack,
  List,
  ListItem,
  Skeleton,
  Stack,
  StackDivider,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  useColorMode,
  useColorModeValue,
  VStack
} from "@chakra-ui/react";
import React, {useRef} from "react";
import {Link as ReactRouterLink, NavigateFunction, useNavigate} from "react-router-dom";
import {FcLike} from "react-icons/fc";
import {SLASH_COMPOSE_SLASH_CLAIM, SLASH_HOW_IT_WORKS} from "../../model/url_constants";
import {ClaimWithIncludes} from "../../client/model/claim";
import {Timestamp} from "firebase/firestore";
import {
  computeDateDisplayString,
  computeNumSubClaimsMessage,
  getAuthorName,
  getAuthorUser,
  getAuthorUserUsername,
  getUserProfileImageUrl
} from "../../views/ViewUtils/common_claim_view_utils";
import {ClaimCreatedAt} from "./ClaimCreatedAt";
import {safeFindSidebarNavItem, SidebarNavItem} from "../Dashboard/dashboard_view_utils";

export interface ClaimWithLastViewedAt {
  claim: ClaimWithIncludes;
  lastViewedAt?: Timestamp;
}

export interface ClaimListColorProps {
  recentClaimLoadingBgColor: string, // = useColorModeValue("white", "gray.700");
  bgColorMode: string; // = useColorModeValue("white", "gray.700");
  claimTextColor: string; // = useColorModeValue("gray.800", "white.800");
  createdAtColor: string; // = useColorModeValue("gray.500", "gray.500");
  authorNameColor: string; // = useColorModeValue("gray.800", "white.900");
  authorUsernameColor: string; // = useColorModeValue("gray.500", "gray.500");
  dividerColor: string; // useColorModeValue("gray.200", "gray.600"),
  welcomeTextColor: string; // useColorModeValue("gray.600", "gray.400")
}

const getDefaultClaimListColorProps = (): ClaimListColorProps => {
  const recentClaimLoadingBgColor = useColorModeValue("white", "gray.700");
  const bgColorMode = useColorModeValue("white", "gray.700");
  const claimTextColor = useColorModeValue("gray.800", "white.800");
  const createdAtColor = useColorModeValue("gray.500", "gray.500");
  const authorNameColor = useColorModeValue("gray.800", "white.900");
  const authorUsernameColor = useColorModeValue("gray.500", "gray.500");
  const dividerColor = useColorModeValue("gray.200", "gray.600");
  const welcomeTextColor = useColorModeValue("gray.600", "gray.400");

  return {
    recentClaimLoadingBgColor,
    claimTextColor,
    bgColorMode,
    createdAtColor,
    authorNameColor,
    authorUsernameColor,
    dividerColor,
    welcomeTextColor
  }
}

export interface ClaimListProps {
  claims?: ClaimWithLastViewedAt[],
  listHeading: string,
  claimListColorProps?: ClaimListColorProps
}

/**
 * This component displays a list of claims.
 * @constructor
 */
const ClaimList = (props: ClaimListProps) => {
  const isFetchingClaims = useRef(false);
  const navigate = useNavigate();
  const defaultClaimListColorProps: ClaimListColorProps = getDefaultClaimListColorProps();
  const colorProps: ClaimListColorProps =
    props.claimListColorProps ? props.claimListColorProps : defaultClaimListColorProps;

  /**
   * Compare function for instances of ClaimWithLastViewedAt. This sorts by `lastViewedAt` in Descending order.
   * @param a
   * @param b
   */
  const sortByMostRecentlyViewed = (a: ClaimWithLastViewedAt, b: ClaimWithLastViewedAt): number => {
    if (a.lastViewedAt && b.lastViewedAt) {
      if (b.lastViewedAt > a.lastViewedAt) {
        return 1;
      } else if (b.lastViewedAt < a.lastViewedAt) {
        return -1;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  const showRecentClaimLoadingListItem = () => {
    if (isFetchingClaims) {
      const height = "20px";
      return <List>
        <ListItem
          key={"empty"}
          w={"450px"}
          bg={colorProps.recentClaimLoadingBgColor}
          boxShadow={"2xl"}
          rounded={"md"}
          p={4}
          m={2}
          overflow={"hidden"}
        >
          <Stack>
            <Skeleton height={height}/>
            <Skeleton height={height}/>
            <Skeleton height={height}/>
            <Skeleton height={height}/>
            <Skeleton height={height}/>
          </Stack>
        </ListItem>
      </List>

    } else {
      return (<Box as={"span"}/>);
    }
  };

  const showClaims = (claims: ClaimWithLastViewedAt[], isDarkMode: boolean) => {
    // This check doesn't hurt, but should never happen due to other logic on this page.
    if (claims === undefined) {
      claims = [];
    }

    return claims.length == 0 ?
      "No Claims" :
      <List>
        {
          claims
            .sort(sortByMostRecentlyViewed)
            .map((recentlyViewedClaim: ClaimWithLastViewedAt) => {
              const claim: ClaimWithIncludes = recentlyViewedClaim.claim;

              const viewedAtText = recentlyViewedClaim.lastViewedAt ?
                `Viewed ${computeDateDisplayString(recentlyViewedClaim.lastViewedAt)}
                (${computeNumSubClaimsMessage(claim.data)})` : "";

              // Get each claim tag...
              const claimTagValue = claim.data.claimTags?.length > 0 && claim.data.claimTags[0] || undefined;
              let tag: SidebarNavItem | undefined;
              if (claimTagValue) {
                tag = safeFindSidebarNavItem(claimTagValue, isDarkMode);
              }

              // NOTE: Extracting this to its own component will cause the `no key` error, so this needs to be defined
              // here in order for Chakra to work properly.
              return (<ListItem
                key={claim.data.id}
                w={{base: "375px", md: "640px", lg: "960px"}}
                bg={colorProps.bgColorMode}
                boxShadow={"2xl"}
                rounded={"md"}
                ml={4}
                mr={4}
                mt={3}
                mb={4}
                p={4}
                overflow={"hidden"}
              >
                <Stack direction={"column"} spacing={2} align={"left"}>
                  <HStack>
                    <Text color={colorProps.createdAtColor} fontSize={"xs"} fontStyle={"italic"}>
                      {viewedAtText}
                    </Text>
                  </HStack>
                  <ReactRouterLink to={"/claims/" + claim.data.id}>
                    <Text fontSize={"xl"} color={colorProps.claimTextColor}>
                      {claim.data.text}
                    </Text>
                  </ReactRouterLink>
                  <HStack>
                    <Stack direction={"row"}
                           spacing={2}
                           align={"center"}
                           width={"100%"}
                    >
                      <AvatarGroup max={2}>
                        <Avatar
                          name={getAuthorName(claim.data.authorId, claim)}
                          src={getUserProfileImageUrl(getAuthorUser(claim.data.authorId ?? "", claim))}
                        />
                      </AvatarGroup>
                      <Stack direction={"column"} spacing={0} fontSize={"xs"} width={"100%"}>
                        <Text color={colorProps.authorNameColor} fontWeight={600}>
                          {getAuthorName(claim.data.authorId, claim)}
                          <Text as={"span"} fontWeight={"normal"} color={colorProps.authorUsernameColor}>
                            &nbsp;({getAuthorUserUsername(claim.data.authorId, claim)})
                          </Text>
                        </Text>
                        <ClaimCreatedAt
                          claimCreationDate={claim.data.createdAt}
                          color={colorProps.authorUsernameColor}
                          fontSize={"xs"}
                        />
                      </Stack>
                      <Box pt={6}>
                        {tag ? <Tag
                          size={"sm"}
                          variant={"subtle"}
                          colorScheme={tag.colorScheme}
                        >
                          <TagLeftIcon as={tag.icon}/>
                          <TagLabel>{tag?.label}</TagLabel>
                        </Tag> : <Box as={"span"}/>}
                      </Box>
                    </Stack>
                  </HStack>
                </Stack>
              </ListItem>)
                ;
            })
        }
      </List>
  };

  // TODO: allow this to be supplied by the caller.
  const renderNoClaimsView = (navigate: NavigateFunction): JSX.Element => {
    return (
      <Stack
        w={{base: "350px", md: "640px", lg: "640px"}}
        bg={colorProps.bgColorMode}
        // overflow={"hidden"}
        rounded={"md"}
        alignItems={"left"}
        p={"4"}
        m="4"
        boxShadow="lg"
        borderRadius="sm"
      >
        <Container maxW={"4xl"} alignItems={"left"}>
          <VStack alignItems={"left"}>
            <HStack as={"header"}>
              <Text
                fontWeight={600}
                fontSize={{base: "2xl", sm: "2xl", lg: "3xl"}}
              >Welcome!</Text>
              <FcLike size={"1.5rem"}/>
            </HStack>

            <Stack
              spacing={{base: 4, sm: 6}}
              divider={<StackDivider borderColor={colorProps.dividerColor}/>}
            >
              <VStack
                spacing={{base: 4, sm: 6}}
                alignItems={"left"}
              >
                <Text
                  alignItems={"left"} textAlign={"left"}
                  align={"left"} alignContent={"left"}
                  color={colorProps.welcomeTextColor}
                  fontSize={"2xl"}
                  fontWeight={"300"}>
                  Thanks for using Sentiment, we're so glad you're here.
                </Text>
              </VStack>
              <Stack direction={{base: "column", md: "row"}}>
                <Button colorScheme={"orange"} onClick={() => navigate(SLASH_COMPOSE_SLASH_CLAIM)}>
                  Make Your First Claim
                </Button>
                <Center>
                  <Text fontSize={"sm"}>(<Text as={"span"} color={"blue.600"} textDecoration={"underline"}>
                    <ReactRouterLink to={SLASH_HOW_IT_WORKS}>How it Works</ReactRouterLink>
                  </Text>)</Text>
                </Center>
              </Stack>
            </Stack>
          </VStack>
        </Container>
      </Stack>
    );
  }

  // Show loading only on the first time.
  const showLoadingScreen = props.claims === undefined;
  // Show "No Recent Claims" if not the first time, but 0 recent claims exist.
  const showNoRecentsScreen = props.claims?.length === 0;

  const {colorMode} = useColorMode()
  const isDarkMode = colorMode === "dark";
  return (
    <Center>
      {/* This is required to align the select list menu with the list (both mobile and desktop)  */}
      <VStack
        w={{base: "375px", md: "640px", lg: "960px"}}
      >
        <Box>
          {/* This section displays a list heading. For now, this isn't used, but if it's ever brought back, we'll */}
          {/* want to be sure not to display it on mobile. */}
          {/* Don't display on mobile; instead let the select list show the label*/}
          {/*<Box*/}
          {/*  display={{base: "none", md: "none", lg: "none"}}*/}
          {/*  width={"full"}*/}
          {/*  alignItems={"left"}*/}
          {/*  padding={"1rem 1rem 0rem 1rem"}*/}
          {/*>*/}
          {/*  <Heading*/}
          {/*    size={"md"}*/}
          {/*    fontWeight={"semibold"}*/}
          {/*    verticalAlign={"middle"}*/}
          {/*    textAlign={"left"}*/}
          {/*  >*/}
          {/*    {props.listHeading}*/}
          {/*  </Heading>*/}
          {/*</Box>*/}
          <Divider
            display={{base: "none", md: "none", lg: "none"}}
            borderColor={colorProps.dividerColor}
            mt={2}
            width={"97%"}
            ml={4}
          />
          {
            showLoadingScreen ? showRecentClaimLoadingListItem() : showNoRecentsScreen ?
              renderNoClaimsView(navigate) : showClaims(props.claims ? props.claims : [], isDarkMode)
          }
        </Box>
      </VStack>
    </Center>
  );
};

export {ClaimList}