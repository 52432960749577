import {useMatch} from "react-router-dom";
import {
  SLASH_DASHBOARD_SLASH_CLAIMS_TAG_BRAINSTORM,
  SLASH_DASHBOARD_SLASH_CLAIMS_TAG_DECIDED,
  SLASH_DASHBOARD_SLASH_CLAIMS_TAG_NEEDS_INFO,
  SLASH_DASHBOARD_SLASH_CLAIMS_TAG_PROPOSAL,
  SLASH_DASHBOARD_SLASH_CLAIMS_TAG_REVISIT,
  SLASH_DASHBOARD_SLASH_MY_CLAIMS,
  SLASH_DASHBOARD_SLASH_RECENT_CLAIMS,
  SLASH_HOW_IT_WORKS
} from "../../model/url_constants";
import {
  MY_CLAIMS,
  RECENT_CLAIMS,
  TAG_BRAINSTORM_LABEL,
  TAG_BRAINSTORM_VALUE,
  TAG_DECIDED_LABEL,
  TAG_DECIDED_VALUE,
  TAG_NEEDS_INFO_LABEL,
  TAG_NEEDS_INFO_VALUE,
  TAG_PROPOSAL_LABEL,
  TAG_PROPOSAL_VALUE,
  TAG_REVISIT_LABEL,
  TAG_REVISIT_VALUE
} from "../../model/constants";
import {FiClock, FiHelpCircle, FiUser} from "react-icons/fi";
import {MdDoneAll, MdOutlineGesture, MdOutlineStorm} from "react-icons/md";
import {VscRequestChanges} from "react-icons/vsc";
import {IconType} from "react-icons";

export interface SidebarNavItem {
  value: string,
  label: string,
  icon: IconType,
  href: string,
  colorScheme?: string,
  color?: string,
}

/**
 * Given the current domain, return the NavBarItem for the current page (this is for mobile view only).
 */
export const computeCurrentSideBarNavItem = (darkMode: boolean): SidebarNavItem => {
  const currentNavId = getCurrentSideBarNavItemId();
  return safeFindSidebarNavItem(currentNavId, darkMode);
}

export const getCurrentSideBarNavItemId = (): string => {
  const recentClaimsMatch = useMatch(SLASH_DASHBOARD_SLASH_RECENT_CLAIMS);
  const myClaimsMatch = useMatch(SLASH_DASHBOARD_SLASH_MY_CLAIMS);

  const tagBrainstormMatch = useMatch(SLASH_DASHBOARD_SLASH_CLAIMS_TAG_BRAINSTORM);
  const tagNeedsInfoMatch = useMatch(SLASH_DASHBOARD_SLASH_CLAIMS_TAG_NEEDS_INFO);
  const tagProposalMatch = useMatch(SLASH_DASHBOARD_SLASH_CLAIMS_TAG_PROPOSAL);
  const tagDecidedMatch = useMatch(SLASH_DASHBOARD_SLASH_CLAIMS_TAG_DECIDED);
  const tagRevisitMatch = useMatch(SLASH_DASHBOARD_SLASH_CLAIMS_TAG_REVISIT);

  if (recentClaimsMatch !== null) {
    // console.debug("Matched: " + RECENT_CLAIMS);
    return RECENT_CLAIMS;
  } else if (myClaimsMatch !== null) {
    // Search for query-params....
    // console.debug("Matched: " + MY_CLAIMS);
    return MY_CLAIMS;
  } else if (tagBrainstormMatch !== null) {
    // console.debug("Matched: " + TAG_BRAINSTORM_VALUE);
    return TAG_BRAINSTORM_VALUE;
  } else if (tagNeedsInfoMatch !== null) {
    // console.debug("Matched: " + TAG_NEEDS_INFO_VALUE);
    return TAG_NEEDS_INFO_VALUE;
  } else if (tagProposalMatch !== null) {
    // console.debug("Matched: " + TAG_PROPOSAL_VALUE);
    return TAG_PROPOSAL_VALUE;
  } else if (tagDecidedMatch !== null) {
    // console.debug("Matched: " + TAG_DECIDED_VALUE);
    return TAG_DECIDED_VALUE;
  } else if (tagRevisitMatch !== null) {
    // console.debug("Matched: " + TAG_REVISIT_VALUE);
    return TAG_REVISIT_VALUE;
  } else {
    console.error("Matched: Empty");
    return "";
  }
}

export const safeFindSidebarNavItem = (sidebarNavItemValue: string, darkMode: boolean): SidebarNavItem => {
  const found = getSidebarNavItems(darkMode)
    .find((element: SidebarNavItem) => element.value === sidebarNavItemValue);
  if (found === undefined) {
    throw new TypeError("Value (" + sidebarNavItemValue + ") should have existed, but did not!");
  }
  return found;
}

export const getSidebarNavItems = (darkMode: boolean): Array<SidebarNavItem> => {
  return [
    {
      value: MY_CLAIMS,
      label: "My Claims",
      icon: FiUser,
      href: SLASH_DASHBOARD_SLASH_MY_CLAIMS,
      // children: [
      //   {
      //     label: "Explore Design Work",
      //     subLabel: "Trending Design to inspire you",
      //     href: "#",
      //     icon: "",
      //   },
      //   {
      //     label: "New & Noteworthy",
      //     subLabel: "Up-and-coming Designers",
      //     href: "#",
      //     icon: "",
      //   },
      // ],
    },
    {
      value: RECENT_CLAIMS,
      label: "Recently Viewed",
      icon: FiClock,
      href: SLASH_DASHBOARD_SLASH_RECENT_CLAIMS,
    },
    {
      value: TAG_BRAINSTORM_VALUE,
      label: TAG_BRAINSTORM_LABEL,
      icon: MdOutlineStorm,
      href: SLASH_DASHBOARD_SLASH_CLAIMS_TAG_BRAINSTORM,
      color: darkMode ? "purple.400" : "purple.500",
      colorScheme: "purple",
    },
    {
      value: TAG_NEEDS_INFO_VALUE,
      label: TAG_NEEDS_INFO_LABEL,
      icon: MdOutlineGesture,
      href: SLASH_DASHBOARD_SLASH_CLAIMS_TAG_NEEDS_INFO,
      color: darkMode ? "orange.400" : "orange",
      colorScheme: "orange",
    },
    {
      value: TAG_PROPOSAL_VALUE,
      label: TAG_PROPOSAL_LABEL,
      icon: VscRequestChanges,
      href: SLASH_DASHBOARD_SLASH_CLAIMS_TAG_PROPOSAL,
      color: darkMode ? "blue.400" : "blue.500",
      colorScheme: "blue",
    },
    {
      value: TAG_DECIDED_VALUE,
      label: TAG_DECIDED_LABEL,
      icon: MdDoneAll,
      href: SLASH_DASHBOARD_SLASH_CLAIMS_TAG_DECIDED,
      color: darkMode ? "green.400" : "green.500",
      colorScheme: "green",
    },
    {
      value: TAG_REVISIT_VALUE,
      label: TAG_REVISIT_LABEL,
      icon: MdOutlineGesture,
      href: SLASH_DASHBOARD_SLASH_CLAIMS_TAG_REVISIT,
      color: darkMode ? "red.400" : "red.400",
      colorScheme: "red",
    },
    {
      value: "how-it-works",
      label: "How it Works",
      icon: FiHelpCircle,
      href: SLASH_HOW_IT_WORKS,
      color: darkMode ? "orange.300" : "orange",
      colorScheme: "orange",
    },
  ];
};