// Summary: This file contains string constants used by the react app.
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";

TimeAgo.setDefaultLocale(en.locale);
TimeAgo.addLocale(en);

export const SLASH = "/" as string;
export const COLON = ":" as string;

export const ADMIN = "admin" as string;
// export const AGREEMENT_METRICS = "agreementMetrics" as string;
export const SUBSCRIBE = "subscribe" as string;
export const CLAIM = "claim" as string;
export const CLAIMS = "claims" as string;
export const CONTEST_SELECTIONS = "contest-selections" as string;
export const COMPOSE = "compose" as string;
export const ERROR = "error" as string;
// export const EXPANSIONS = "expansions" as string;
export const DASHBOARD: string = "dashboard" as string;
export const HOME: string = "home" as string;
export const ID: string = "id" as string;
export const ME: string = "me" as string;
export const RECENT_CLAIMS: string = "recent-claims" as string;
export const MY_CLAIMS: string = "my-claims" as string;
export const TAGS: string = "tags" as string;
export const HOW_IT_WORKS: string = "how-it-works" as string;
export const SIGN_IN = "sign_in" as string;
export const USERS = "users" as string;
// export const AUTHOR_ID = "authorId" as string;
export const COLON_ID: string = COLON + ID as string;
const OOPS = "Sentibot Claim" as string;
export const OOPS_HEADER = OOPS as string;
export const OOPS_MESSAGE_GENERIC: string =
  "Sentiment is having a problem -- try your request again in a few moments." as string;
export const NOT_SUBSCRIBED: string = "NOT_SUBSCRIBED" as string;

// Dashboard Constants
export const TAG_BRAINSTORM_VALUE = "brainstorm";
export const TAG_NEEDS_INFO_VALUE = "needs-info";
export const TAG_PROPOSAL_VALUE = "proposal";
export const TAG_DECIDED_VALUE = "decided";
export const TAG_REVISIT_VALUE = "revisit";

export const TAG_BRAINSTORM_LABEL = "Brainstorms";
export const TAG_NEEDS_INFO_LABEL = "Needs Info";
export const TAG_PROPOSAL_LABEL = "Proposals";
export const TAG_DECIDED_LABEL = "Decided";
export const TAG_REVISIT_LABEL = "Revisit";
