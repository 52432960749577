import {useLocation, useNavigate} from "react-router-dom";
import {Button} from "@chakra-ui/react";
import {prevLocationTracker} from "../../utils/location_utils";
import {SLASH_SIGN_IN} from "../../model/url_constants";
import React from "react";

export const SignInButton = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Button
      as={"a"}
      fontSize={"sm"}
      fontWeight={400}
      variant={"link"}
      onClick={() => {
        // Set the location's path (if any)s
        const from = location.pathname;
        if (from) {
          prevLocationTracker.setPreviousLocationPath(from);
        }

        navigate(SLASH_SIGN_IN,
          {
            replace: true,
            state: {from: location}
          });
      }}>
      Sign In
    </Button>
  );
};