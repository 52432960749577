import React from "react";
import {Link} from "react-router-dom";
import {Box, Flex, HStack, Image, SkeletonCircle, Text, useColorModeValue,} from "@chakra-ui/react";
import {SLASH_COMPOSE_SLASH_CLAIM, SLASH_DASHBOARD} from "../../model/url_constants";
import {SentimentUser} from "../../client/model";
import {SignedInUserDrawer} from "./SignedInUserDrawer/SignedInUserDrawer";
import {SignInButton} from "./SignInButton";

interface NavBarProps {
  isSigningIn: boolean;
  // if this is undefined, we consider the user to not be signed in.
  signedInSentimentUser?: SentimentUser;
}

export const NavBar = (props: NavBarProps) => {

  const linkColor = useColorModeValue("orange.400", "orange");
  const linkHoverColor = useColorModeValue("gray.500", "gray.200");

  return (
    <Flex
      bg={useColorModeValue("white", "gray.800")}
      color={useColorModeValue("gray.600", "white")}
      minH={"60px"}
      borderBottom={1}
      borderStyle={"solid"}
      borderColor={useColorModeValue("gray.200", "gray.900")}
    >
      <HStack
        width={"full"}
      >
        {/*Desktop NavDrawer*/}
        <HStack
          width={"100%"}
          marginLeft={3}
        >
          {/*LOGO*/}
          <Box width={"50px"}>
            {/* Only show this when a user is signed in */}
            {props.signedInSentimentUser &&
              <Link to={SLASH_DASHBOARD}>
                <Image
                  height={"40px"}
                  // width={"100px"}
                  src={"/icons/logo192ot.png"}/>
              </Link>}

            {/* Only show this when a user is signed out */}
            {!props.signedInSentimentUser &&
              <Link to={SLASH_DASHBOARD}>
                <Image
                  height={"40px"}
                  src={"/icons/logo192ot.png"}/>
              </Link>}
          </Box>
          {/*<NavDrawer {...props}/>*/}

          <Box
            visibility={{base: "visible", md: "visible", lg: "visible"}}
          >
            <Link
              to={SLASH_COMPOSE_SLASH_CLAIM}>
              <Text margin={"0"}
                // p={2}
                    fontSize={"sm"}
                    fontWeight={500}
                    color={linkColor}
                    _hover={{
                      textDecoration: "none",
                      color: linkHoverColor,
                    }}
              >
                Make a Claim
              </Text>
            </Link>
          </Box>

        </HStack>

        {/* RIGHT_SIDE_INFO */}
        <Box>
          <Flex
            justifyContent={"flex-end"}
            height={"40px"}
            paddingRight={"1rem"}
          >
            {
              props.isSigningIn ? (<SkeletonCircle size='32px'/>) : (
                props.signedInSentimentUser ?
                  (
                    <SignedInUserDrawer signedInSentimentUser={props.signedInSentimentUser}/>
                  )
                  : (
                    <SignInButton/>
                  )
              )
            }
          </Flex>
        </Box>
      </HStack>
    </Flex>
  );
}

// interface NavItem {
//   label: string;
//   subLabel?: string;
//   href: string;
//   icon?: IconType;
//   children?: Array<NavItem>;
// }

// const NavDrawer = (props: NavBarProps) => {
//
//   const buttonIconColor = useColorModeValue("orange.400", "orange");
//   const navigate = useNavigate();
//   const navItems = props.signedInSentimentUser ? SIGNED_IN_NAV_ITEMS : SIGNED_OUT_NAV_ITEMS;
//   const {isOpen, onOpen, onClose} = useDisclosure()
//
//   return (
//     <>
//       <Box
//         cursor={"pointer"}
//         onClick={onOpen}
//         mr={2}
//       >
//         <HamburgerIcon boxSize={"5"}/>
//       </Box>
//
//       {/* This is what is displayed on-click */}
//       <Drawer
//         isOpen={isOpen}
//         placement='left'
//         onClose={onClose}
//       >
//         <DrawerOverlay/>
//         <DrawerContent>
//           <DrawerCloseButton colorScheme="orange" marginTop="0.5rem"/>
//           <DrawerHeader
//             paddingBottom={0}
//           >
//             <HStack>
//               <Center className={"logo"}>
//                 {/* Only show this when a user is signed in */}
//                 {props.signedInSentimentUser &&
//                   <Link
//                     to={SLASH_DASHBOARD}
//                     onClick={onClose}
//                   >
//                     <Image
//                       height={"40px"}
//                       src={"/icons/logo192ot.png"}/>
//                   </Link>}
//
//                 {/* Only show this when a user is signed out */}
//                 {!props.signedInSentimentUser &&
//                   <Link
//                     to={SLASH}
//                     onClick={onClose}
//                   >
//                     <Image
//                       height={"40px"}
//                       src={"/icons/logo192ot.png"}/>
//                   </Link>}
//               </Center>
//             </HStack>
//             <Divider paddingTop={"1rem"} paddingBottom={0}/>
//           </DrawerHeader>
//           <DrawerBody>
//             <VStack>
//               {navItems.map((navItem, index) => (
//                 <Button
//                   key={index}
//                   width={"100%"}
//                   justifyContent={"flex-start"}
//                   textAlign={"left"}
//                   variant={"ghost"}
//                   onClick={
//                     () => {
//                       navigate(navItem.href);
//                       onClose();
//                     }
//                   }
//                   leftIcon={<Icon as={navItem.icon} fontSize={"lg"} color={buttonIconColor}/>}
//                 >
//                   <Text
//                     width={"100%"}
//                     textAlign={"left"}
//                     fontSize={"inherit"}
//                     fontWeight={"normal"}
//                   >
//                     {navItem.label}
//                   </Text>
//                 </Button>
//
//               ))}
//             </VStack>
//           </DrawerBody>
//         </DrawerContent>
//       </Drawer>
//     </>
//   );
// };

// const SIGNED_IN_NAV_ITEMS: Array<NavItem> = [
//   {
//     label: "Dashboard",
//     href: SLASH_DASHBOARD,
//     icon: MdOutlineDashboard,
//   },
//   {
//     label: "Make a Claim",
//     href: SLASH_COMPOSE_SLASH_CLAIM,
//     icon: IoCreateOutline,
//     // children: [
//     //     {
//     //         label: 'Explore Design Work',
//     //         subLabel: 'Trending Design to inspire you',
//     //         href: '#',
//     //     },
//     //     {
//     //         label: 'New & Noteworthy',
//     //         subLabel: 'Up-and-coming Designers',
//     //         href: '#',
//     //     },
//     // ],
//   },
//   {
//     label: "How it Works",
//     href: SLASH_HOW_IT_WORKS,
//     // undefined icon is the question mark.
//   },
// ];

// const SIGNED_OUT_NAV_ITEMS: Array<NavItem> = [
//   {
//     label: "Make a Claim",
//     href: SLASH_COMPOSE_SLASH_CLAIM,
//     icon: IoCreateOutline,
//     // children: [
//     //     {
//     //         label: 'Explore Design Work',
//     //         subLabel: 'Trending Design to inspire you',
//     //         href: '#',
//     //     },
//     //     {
//     //         label: 'New & Noteworthy',
//     //         subLabel: 'Up-and-coming Designers',
//     //         href: '#',
//     //     },
//     // ],
//   },
//   {
//     label: "How it Works",
//     href: SLASH_HOW_IT_WORKS,
//     // undefined icon is the question mark.
//   },
// ];