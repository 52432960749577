import {Box, Button, Flex, Icon, Text, useColorMode} from "@chakra-ui/react";
import React from "react";
import {useNavigate} from "react-router-dom";
import {computeCurrentSideBarNavItem, SidebarNavItem} from "../dashboard_view_utils";

export interface DesktopSidebarNavItemProps {
  navItem: SidebarNavItem;
}

export const DesktopSidebarNavItem = (props: DesktopSidebarNavItemProps) => {
  const {colorMode} = useColorMode()
  const currentNavBarItem: SidebarNavItem = computeCurrentSideBarNavItem(colorMode === "dark");

  // const match = useMatch(props.navItem.href)
  const navigate = useNavigate();

  return (
    <Box w={"100%"}>
      <Flex
        flexDirection="column"
        width={"100%"}
        alignItems={"flex-start"}
      >
        <Button
          borderRadius={5}
          width={"100%"}
          isActive={props.navItem.value === currentNavBarItem.value}
          textAlign={"left"}
          variant={"ghost"}
          fontWeight={"normal"}
          leftIcon={<Icon as={props.navItem.icon} textColor={props.navItem.color}/>}
          onClick={
            () => {
              navigate(props.navItem.href)
            }
          }
        >
          <Text w={"100%"}>{props.navItem.label}</Text>
        </Button>
      </Flex>
    </Box>
  );
}