import {SLASH_HOME} from "../model/url_constants";

const STORAGE_KEY = "PreviousLocationPath";

/**
 * A module that centralizes all "previous location" information.
 */
export const prevLocationTracker = {
  getPreviousLocationPath(): string {
    try {
      const retrievedLocationDataString = sessionStorage.getItem(STORAGE_KEY);
      // Type guard to ensure it's a string before parsing
      if (typeof retrievedLocationDataString === "string") {
        const parsedLocationData: {
          previousLocationPath: string,
          previousQueryParams: string
        } | null = JSON.parse(retrievedLocationDataString);

        if (parsedLocationData?.previousQueryParams) {
          return parsedLocationData?.previousLocationPath + "?" + parsedLocationData?.previousQueryParams;
        } else {
          return parsedLocationData?.previousLocationPath || SLASH_HOME;
        }
      } else {
        // Handle cases where it's not a string (e.g., return SLASH_HOME)
        return SLASH_HOME;
      }
    } catch (error) {
      console.error("Error retrieving previous location path:", error);
      return SLASH_HOME;
    }
  },

  setPreviousLocationPath(previousLocationPath: string, previousQueryParams?: string): void {
    console.debug("Setting previousLocationPath to " + previousLocationPath);

    if (previousQueryParams) {
      sessionStorage.setItem(
        STORAGE_KEY,
        JSON.stringify({previousLocationPath, previousQueryParams})
      );
    } else {
      sessionStorage.setItem(
        STORAGE_KEY,
        JSON.stringify({previousLocationPath})
      );
    }


  },
};
