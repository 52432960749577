import React from "react";
import {
  Avatar,
  Box,
  Button,
  Center,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  IconButton,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import {SentimentUser} from "../../../client/model";
import {BillingButton, SubscribeButton} from "./UserDrawerButtons";
import {NOT_SUBSCRIBED} from "../../../model/constants";
import SignOutButton from "../../auth/firebase/SignOutButton";
import {SntBadge} from "../../../views/ViewUtils/snt_view_utils";
import {FaRegQuestionCircle} from "react-icons/fa";
import {SLASH_SUBSCRIBE} from "../../../model/url_constants";

interface SignedInUserDrawerProps {
  signedInSentimentUser?: SentimentUser;
}

/**
 * Determines if a Sentiment user is currently subscribed to any Sentiment plan (used to display the correct menu item).
 */
const userCurrentlySubscribed = (signedInUser?: SentimentUser): boolean => {
  if (signedInUser) {
    console.debug("Current Subscription = " + signedInUser.subscriptionDetails.currentSubscription);
    return signedInUser.subscriptionDetails != null &&
      signedInUser.subscriptionDetails.currentSubscription != null &&
      signedInUser.subscriptionDetails.currentSubscription !== NOT_SUBSCRIBED;
  } else {
    return false;
  }
}

// This drawer is displayed only when the user is signed in. When the user is signed out, a sign-in button is displayed.
export const SignedInUserDrawer = (props: SignedInUserDrawerProps) => {

  const {isOpen, onOpen, onClose} = useDisclosure()

  return (
    <>
      <HStack p={0} m={0}>
        <Center>
          <SntBadge sntAmount={props.signedInSentimentUser?.amountSnt || 0}/>
          <Popover>
            <PopoverTrigger>
              <IconButton
                aria-label={"About SNT"}
                variant="unstyled"
                backgroundColor={"unset"}
                size={"sm"}
                icon={<FaRegQuestionCircle/>}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow/>
              <PopoverCloseButton/>
              <PopoverHeader><Text fontWeight={"semibold"}>What is SNT?</Text></PopoverHeader>
              <PopoverBody>
                <Text>
                  SNT credits power the Sentiment platform. You'll use one SNT whenever you post a Claim or cast a
                  vote.
                  <br/><br/>
                  We give you 200 free SNT when you first sign up, and if you ever need more,
                  just <Link color={"blue.600"} href={SLASH_SUBSCRIBE}>subscribe</Link> to one of our paid plans
                  to get regular SNT refills.
                </Text>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Center>
        <Box
          p={0}
          m={0}
          cursor={"pointer"}
          onClick={onOpen}>
          <Avatar
            size={"sm"}
            name={props.signedInSentimentUser?.name || ""}
            src={props.signedInSentimentUser?.profileImageUrl || ""}
          />
        </Box>
      </HStack>

      {/* This is what is displayed on-click */}
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
      >
        <DrawerOverlay/>
        <DrawerContent>
          <DrawerCloseButton colorScheme="orange" marginTop="0.5rem"/>
          <DrawerHeader
            paddingBottom={0}
          >
            <HStack>
              <Avatar
                size={"sm"}
                name={props.signedInSentimentUser?.name}
                src={props.signedInSentimentUser?.profileImageUrl}
              />
              <VStack>
                <Text as={"span"} fontSize={"sm"} fontWeight={"bold"}>
                  {props.signedInSentimentUser?.name}
                  <br/>
                  <Text as={"span"} fontSize={"sm"}
                        fontWeight={"semibold"}
                        color={"blue.400"}
                        textDecoration={"underline"}
                  >
                    {props.signedInSentimentUser?.username}
                  </Text>
                  {/*</Link>*/}
                </Text>
              </VStack>
            </HStack>
            <Divider paddingTop={"1rem"} paddingBottom={0}/>
          </DrawerHeader>
          <DrawerBody>
            <VStack>
              <Button
                width={"100%"}
                justifyContent={"flex-start"}
                textAlign={"left"}
                variant={"ghost"}
                fontWeight={"normal"}
                size={"sm"}
                // leftIcon={<BiDiamond/>}
              >
                <Text
                  width={"100%"}
                  textAlign={"left"}
                  fontWeight={"normal"}
                  fontSize={"sm"}
                >💎&nbsp;&nbsp;You have <SntBadge sntAmount={props.signedInSentimentUser?.amountSnt || 0}
                                                 hideDiamond={true}/>
                </Text>
              </Button>

              <Divider/>

              {
                userCurrentlySubscribed(props.signedInSentimentUser) ?
                  (<BillingButton/>) : (<SubscribeButton/>)
              }
              <Divider/>
              <SignOutButton/>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer></>
  );
}