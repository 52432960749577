import {Claim, ClaimWithIncludes} from "../../client/model/claim";
import FirestoreUser from "../../client/model/firestore_user";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import {Timestamp} from "firebase/firestore";

TimeAgo.setDefaultLocale(en.locale);
TimeAgo.addLocale(en);
const timeAgo: TimeAgo = new TimeAgo("en-US")

/**
 * Given a {@link Timestamp}, compute a plain-text string that displays the timestamp in a human-readable format.
 * @param timestamp The Timestamp to compute.
 */
export const computeDateDisplayString = (timestamp: Timestamp | undefined): string => {
  if (isString(timestamp) && (timestamp as unknown as string).length > 0) {
    const temp: string = timestamp as unknown as string;
    try {
      return timeAgo.format(new Date(temp)) // <-- timestamp is a string
    } catch (e) {
      console.log(e);
    }
  } else if (timestamp && timestamp.toDate) { // <-- timestamp is a Timestamp
    try {
      return timeAgo.format(new Date(timestamp.toDate()));
    } catch (e) {
      console.log(e);
    }
  }

  return "recently" // <-- timestamp is unknown or null
}

const isString = (value: any): boolean => {
  return typeof value === "string" || value instanceof String;
}


export const computeDateDisplayStringFromClaim = (claim: Claim | undefined): string => {
  return computeDateDisplayString(claim?.createdAt);
}

/**
 * Determines if a string value is a number.
 * @param value
 */
export function isNumber(value: string | number): boolean {
  return ((value != null) &&
    (value !== "") &&
    !isNaN(Number(value.toString())));
}

export const getAuthorUser = (claimAuthorId: string, claimWithIncludes: ClaimWithIncludes | undefined)
  : FirestoreUser | undefined => {
  if (claimWithIncludes) {
    if (claimWithIncludes.includes) {
      if (claimWithIncludes.includes.users) {
        const usersMap
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          = new Map<string, FirestoreUser>(Object.entries(claimWithIncludes!.includes!.users!));
        return usersMap.get(claimAuthorId) as FirestoreUser;
      } else {
        console.warn("Claim had no users in its includes to load author from.");
      }
    } else {
      console.error("Claim had no includes to load author from.");
    }
  } else {
    console.warn("No Claim to load author from.");
  }
  return undefined;
}

export const getAuthorUserUsername = (claimAuthorId: string, claimWithIncludes: ClaimWithIncludes | undefined):
  string => {
  return getAuthorUser(claimAuthorId, claimWithIncludes)?.username ?? "unknown";
}

export const getAuthorName = (claimAuthorId: string, claimWithIncludes: ClaimWithIncludes | undefined): string => {
  return getAuthorUser(claimAuthorId, claimWithIncludes)?.name ?? "unknown";
}

export const getUserProfileImageUrl = (claimAuthor: FirestoreUser | undefined): string => {
  return claimAuthor?.profileImageUrl ?? "";
}

/**
 * Assembles the message that should display the number of subclaims for a given claim, according to the following
 * rules:
 *
 * 1. If a claim has N=0 subclaims, display `No subclaims`
 * 2. If a claim has N=1 subclaims, display `1 subclaim`
 * 3. If a claim has N>1 subclaims, display `N subclaims`
 *
 * @param claim A claim, potentially with subclaims.
 *
 * @returns A string that properly displays the number of subclaims.
 **/
export const computeNumSubClaimsMessageViaIncludes = (claim: ClaimWithIncludes | undefined): string => {
  let numSubClaims = 0;
  if (claim && claim.includes) {
    if (claim.includes.subclaims instanceof Map) {
      numSubClaims = claim.includes.subclaims?.size ?? 0;
    } else {
      numSubClaims = Object.keys(claim.includes.subclaims ?? {}).length;
    }
  }

  if (numSubClaims <= 0) {
    return "No Subclaims";
  } else if (numSubClaims == 1) {
    return "1 Subclaim";
  } else {
    return numSubClaims + " Subclaims";
  }
}

/**
 * Assembles the message that should display the number of subclaims for a given claim, according to the following
 * rules:
 *
 * 1. If a claim has N=0 subclaims, display `No subclaims`
 * 2. If a claim has N=1 subclaims, display `1 subclaim`
 * 3. If a claim has N>1 subclaims, display `N subclaims`
 *
 * @param claim A claim, potentially with subclaims.
 *
 * @returns A string that properly displays the number of subclaims.
 **/
export const computeNumSubClaimsMessage = (claim: Claim | undefined): string => {
  let numSubClaims = 0;
  if (claim && claim.claimMetrics) {
    numSubClaims = parseInt(claim.claimMetrics.numSubclaims, 10);
    if (isNaN(numSubClaims)) {
      numSubClaims = 0;
    }
  }

  if (numSubClaims <= 0) {
    return "No Subclaims";
  } else if (numSubClaims == 1) {
    return "1 Subclaim";
  } else {
    return numSubClaims + " Subclaims";
  }
}
