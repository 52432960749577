import {
  Box,
  Button,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  useColorMode
} from "@chakra-ui/react";
import React from "react";
import {ChevronDownIcon} from "@chakra-ui/icons";
import {useNavigate} from "react-router-dom";
import {LayoutProps} from "@chakra-ui/styled-system";
import {
  HOW_IT_WORKS,
  MY_CLAIMS,
  RECENT_CLAIMS,
  TAG_BRAINSTORM_VALUE,
  TAG_DECIDED_VALUE,
  TAG_NEEDS_INFO_VALUE,
  TAG_PROPOSAL_VALUE,
  TAG_REVISIT_VALUE
} from "../../../model/constants";
import {GoArrowRight} from "react-icons/go";
import {computeCurrentSideBarNavItem, safeFindSidebarNavItem, SidebarNavItem} from "../dashboard_view_utils";

/**
 * A React component that renders the Dashboard's Sidebar Navigation Menu when the app is in "Mobile" viewing mode.
 * @constructor
 */
export const MobileSidebar = (props: LayoutProps) => {
  const navigate = useNavigate();

  const {colorMode} = useColorMode()
  const isDarkMode = colorMode === "dark";

  const currentNavBarItem: SidebarNavItem = computeCurrentSideBarNavItem(isDarkMode);

  const recentClaimsNavItem: SidebarNavItem = safeFindSidebarNavItem(RECENT_CLAIMS, isDarkMode);
  const myClaimsNavItem: SidebarNavItem = safeFindSidebarNavItem(MY_CLAIMS, isDarkMode);

  const claimsTagBrainstormNavItem: SidebarNavItem = safeFindSidebarNavItem(TAG_BRAINSTORM_VALUE, isDarkMode);
  const claimsTagNeedsInfoNavItem: SidebarNavItem = safeFindSidebarNavItem(TAG_NEEDS_INFO_VALUE, isDarkMode);
  const claimsTagProposalNavItem: SidebarNavItem = safeFindSidebarNavItem(TAG_PROPOSAL_VALUE, isDarkMode);
  const claimsTagDecidedNavItem: SidebarNavItem = safeFindSidebarNavItem(TAG_DECIDED_VALUE, isDarkMode);
  const claimsTagRevistNavItem: SidebarNavItem = safeFindSidebarNavItem(TAG_REVISIT_VALUE, isDarkMode);

  const howItWorksNavItem: SidebarNavItem = safeFindSidebarNavItem(HOW_IT_WORKS, isDarkMode);

  return (
    <Box
      {...props}
      p={0}
    >
      <Menu>
        <MenuButton p={"16px"} m={0} as={Button} colorScheme="gray">
          <Text as={"span"} display={"flex"}>
            <Text pr={2} pt={"2px"} as={"span"}><Icon as={currentNavBarItem.icon}/></Text>
            <Text as={"span"}>{currentNavBarItem.label} <ChevronDownIcon/></Text>
          </Text>
        </MenuButton>
        <MenuList minWidth="240px">
          <MenuOptionGroup title="My Claims" type="radio" defaultValue={currentNavBarItem.value}>
            {/* This is currently embedded is this way because it's unclear how to pass the `MenuOptionGroup` props
            down into each MenuItemOption if `MenuItemOption` a single component. Passing the defaultValue overtly
            doesn't  have any effect, so the left-arrow and selected items don't work properly. */}
            {/*<MobileSidebarNavItem navItem={safeFindSidebarNavItem(RECENT_CLAIMS)}/>*/}

            {/*My Claims*/}
            <MenuItemOption
              value={myClaimsNavItem.value}
              icon={<GoArrowRight/>}
              onClick={
                () => {
                  navigate(myClaimsNavItem.href);
                }}
            >
              <HStack as={"span"} display={"flex"}>
                <Icon as={myClaimsNavItem.icon}/>
                <Text as={"span"}>{myClaimsNavItem.label}</Text>
              </HStack>
            </MenuItemOption>

            {/*Recent Claims*/}
            <MenuItemOption
              value={recentClaimsNavItem.value}
              icon={<GoArrowRight/>}
              onClick={
                () => {
                  navigate(recentClaimsNavItem.href);
                }}
            >
              <HStack as={"span"} display={"flex"}>
                <Icon as={recentClaimsNavItem.icon}/>
                <Text size={"sm"} as={"span"}>{recentClaimsNavItem.label}</Text>
              </HStack>
            </MenuItemOption>

            <MenuDivider/>

            <Text pt={1} pb={1} fontWeight={"bold"} fontSize={"sm"} pl={2}>Action Items</Text>

            {/*Proposal Claims*/}
            <MenuItemOption
              value={claimsTagProposalNavItem.value}
              icon={<GoArrowRight/>}
              onClick={
                () => {
                  navigate(claimsTagProposalNavItem.href);
                }}
            >
              <HStack as={"span"} display={"flex"}>
                <Icon as={claimsTagProposalNavItem.icon} textColor={claimsTagProposalNavItem.color}/>
                <Text as={"span"}>{claimsTagProposalNavItem.label}</Text>
              </HStack>
            </MenuItemOption>

            {/*Brainstorm Claims*/}
            <MenuItemOption
              value={claimsTagBrainstormNavItem.value}
              icon={<GoArrowRight/>}
              onClick={
                () => {
                  navigate(claimsTagBrainstormNavItem.href);
                }}
            >
              <HStack as={"span"} display={"flex"}>
                <Icon as={claimsTagBrainstormNavItem.icon} textColor={claimsTagBrainstormNavItem.color}/>
                <Text as={"span"}>{claimsTagBrainstormNavItem.label}</Text>
              </HStack>
            </MenuItemOption>

            {/*Needs Info Claims*/}
            <MenuItemOption
              value={claimsTagNeedsInfoNavItem.value}
              icon={<GoArrowRight/>}
              onClick={
                () => {
                  navigate(claimsTagNeedsInfoNavItem.href);
                }}
            >
              <HStack as={"span"} display={"flex"}>
                <Icon as={claimsTagNeedsInfoNavItem.icon} textColor={claimsTagNeedsInfoNavItem.color}/>
                <Text as={"span"}>{claimsTagNeedsInfoNavItem.label}</Text>
              </HStack>
            </MenuItemOption>

            {/*Decided Claims*/}
            <MenuItemOption
              value={claimsTagDecidedNavItem.value}
              icon={<GoArrowRight/>}
              onClick={
                () => {
                  navigate(claimsTagDecidedNavItem.href);
                }}
            >
              <HStack as={"span"} display={"flex"}>
                <Icon as={claimsTagDecidedNavItem.icon} textColor={claimsTagDecidedNavItem.color}/>
                <Text as={"span"}>{claimsTagDecidedNavItem.label}</Text>
              </HStack>
            </MenuItemOption>

            {/*Revisit Claims*/}
            <MenuItemOption
              value={claimsTagRevistNavItem.value}
              icon={<GoArrowRight/>}
              onClick={
                () => {
                  navigate(claimsTagRevistNavItem.href);
                }}
            >
              <HStack as={"span"} display={"flex"}>
                <Icon as={claimsTagRevistNavItem.icon} textColor={claimsTagRevistNavItem.color}/>
                <Text as={"span"}>{claimsTagRevistNavItem.label}</Text>
              </HStack>
            </MenuItemOption>
          </MenuOptionGroup>
          <MenuDivider/>
          <MenuOptionGroup title="More Info" type="radio" defaultValue={currentNavBarItem.value}>

            {/*How it Works*/}
            <MenuItemOption
              value={howItWorksNavItem.value}
              icon={<GoArrowRight/>}
              onClick={
                () => {
                  navigate(howItWorksNavItem.href);
                }}
            >
              <HStack as={"span"} display={"flex"}>
                <Icon as={howItWorksNavItem.icon} textColor={howItWorksNavItem.color}/>
                <Text as={"span"}>{howItWorksNavItem.label}</Text>
              </HStack>
            </MenuItemOption>

          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </Box>
  )
    ;
}
