import {Text} from "@chakra-ui/react";
import React from "react";
import {Timestamp} from "firebase/firestore";
import {computeDateDisplayString} from "../../views/ViewUtils/common_claim_view_utils";

export interface ClaimCreatedAtProps {
  claimCreationDate: Timestamp,
  color: string,
  fontSize: string,
}

/**
 * Renders the "Created at" text for a claim.
 * @constructor
 * @param props A {@link ClaimCreatedAtProps} for when this claim was created.
 */
export function ClaimCreatedAt(props: ClaimCreatedAtProps): JSX.Element {
  return (
    <Text
      color={props.color}
      padding={"0"}
      fontSize={props.fontSize}
    >
      <Text fontStyle={"italic"} as={"span"}>Created </Text><b>{computeDateDisplayString(props.claimCreationDate)}</b>
    </Text>);
}