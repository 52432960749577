import React, {useEffect, useRef, useState} from "react";
import {fetchMyClaimsUrl} from "../../../model/url_constants";
import {useAuth} from "../../../components/auth/AuthContext";
import sentimentAxios from "../../../utils/axios";
import {SentimentUser} from "../../../client/model";
import {AxiosResponse, isAxiosError} from "axios";
import {ClaimList, ClaimWithLastViewedAt} from "../../../components/claims/ClaimList";
import {
  TAG_BRAINSTORM_VALUE,
  TAG_DECIDED_VALUE,
  TAG_NEEDS_INFO_VALUE,
  TAG_PROPOSAL_VALUE,
  TAG_REVISIT_VALUE
} from "../../../model/constants";
import {getCurrentSideBarNavItemId} from "../../../components/Dashboard/dashboard_view_utils";

const MyClaimsView = () => {

  // React State (use undefined to detect first load to only show loading screen on first load)
  // From Bard: useRef persists across renders, so is the best way to make sure we don't load data twice.
  const isFetchingClaims = useRef(false);
  const [myClaims, setMyClaims] = useState<ClaimWithLastViewedAt[] | undefined>(undefined);
  const [currentSideBarNavItemId, setCurrentSideBarNavItemId] = useState<string>("");

  const {getSignedInSentimentUser, isSignedIn} = useAuth();
  const signedInSentimentUser: SentimentUser | undefined = getSignedInSentimentUser();

  const tempCurrentSideBarNavItemId = getCurrentSideBarNavItemId();
  if (currentSideBarNavItemId !== tempCurrentSideBarNavItemId) {
    console.debug("SWITCHED NAV");
    setCurrentSideBarNavItemId(tempCurrentSideBarNavItemId); // <-- Navbar changed.
  }

  useEffect(() => {
    (async () => {
      // Don't fetch multiple at the same time, and only fetch if there's a signed-in user that can provide a JWT to
      // axios.
      if (!isFetchingClaims.current && isSignedIn()) {
        isFetchingClaims.current = true;
        // Fetch MyClaims
        const signedInSentimentUserId = getSignedInSentimentUser()?.id;
        if (signedInSentimentUserId) {
          try {
            const myClaims: ClaimWithLastViewedAt[]
              = await fetchMyClaims(signedInSentimentUserId, currentSideBarNavItemId);
            setMyClaims(myClaims);
          } catch (error) {
            // Set to `undefined` to show a loader screen; set to [] to show welcome screen. In this csae, showing the
            // loader is probably appropriate since we can't get recent claims anyway.
            setMyClaims(undefined);
            // console.error(error);
          }
        }
        isFetchingClaims.current = false;
      }
    })();
  }, [signedInSentimentUser, currentSideBarNavItemId]);  // <-- Only trigger if signedInSentimentUser changes.


  /**
   * Helper function to compute any tags necessary to load 'my-claims'
   */
  const computeTags = (currentSideBarNavItemId: string) => {
    switch (currentSideBarNavItemId) {
      case TAG_BRAINSTORM_VALUE: {
        return TAG_BRAINSTORM_VALUE;
      }
      case TAG_NEEDS_INFO_VALUE: {
        return TAG_NEEDS_INFO_VALUE;
      }
      case TAG_PROPOSAL_VALUE: {
        return TAG_PROPOSAL_VALUE;
      }
      case TAG_DECIDED_VALUE: {
        return TAG_DECIDED_VALUE;
      }
      case TAG_REVISIT_VALUE: {
        return TAG_REVISIT_VALUE;
      }
      default: {
        return "";
      }
    }
  }

  /**
   * Fetch the most recent claims for the signed in user. This function is only ever called under the correct conditions
   * (i.e., (a) a user is signed in that can provide a JWT to axios; (b) a different call to this function is not
   * currently in-flight).
   */
    // TODO: Fetch proper pagination
  const fetchMyClaims = async (
      signedInSentimentUserId: string, currentSideBarNavItemId: string
    ): Promise<Array<ClaimWithLastViewedAt>> => {
      const tags = computeTags(currentSideBarNavItemId);
      console.debug("Tags: " + tags);

      // NOTE: See doc above; the caller of this function guarantees necessary preconditions.
      console.debug("Fetching MyClaims in progress.")
      const url: string = fetchMyClaimsUrl(signedInSentimentUserId);
      console.debug("Fetching MyClaims from " + url)
      return sentimentAxios.get(url, {params: {tags: tags}})
        .then((response: AxiosResponse<any, any> | void) => {
            if (response && response.data) {
              const axiosResponse = response as AxiosResponse;
              return (axiosResponse.data as Array<any>)
                .map(item => {
                    return {
                      claim: {
                        data: {
                          ...item.data
                        },
                        includes: {
                          ...item.includes
                        }
                      }
                    } as ClaimWithLastViewedAt;
                  }
                );
            } else {
              if (isAxiosError(response)) {
                throw new Error("Whoops!");
              } else {
                return new Array<ClaimWithLastViewedAt>();
              }
            }
          }
        );
    };

  // Allow myClaims to be undefined. This is a signal to show the loading screen.
  return (<ClaimList claims={myClaims} listHeading={"My Claims"}/>);
};

export default MyClaimsView;