import React, {useEffect, useRef, useState} from "react";
import {RecentlyViewedClaimMeta} from "../../../client/model/recent_claim_meta";
import {fetchRecentlyViewedClaimsUrl} from "../../../model/url_constants";
import {firebaseApp} from "../../../utils/firebase";
import {ClaimWithIncludes} from "../../../client/model/claim";
import {doc, Firestore, getDoc, getFirestore, Timestamp} from "firebase/firestore";
import {useAuth} from "../../../components/auth/AuthContext";
import sentimentAxios from "../../../utils/axios";
import {SentimentUser} from "../../../client/model";
import {AxiosResponse, isAxiosError} from "axios";
import {ClaimList} from "../../../components/claims/ClaimList";

interface RecentlyViewedClaim {
  lastViewedAt: Timestamp;
  claim: ClaimWithIncludes;
}

const RecentlyViewedClaimsView = () => {

  const db: Firestore = getFirestore(firebaseApp);

  // React State (use undefined to detect first load to only show loading screen on first load)
  // From Bard: useRef persists across renders, so is the best way to make sure we don't load data twice.
  const isFetchingRecentClaims = useRef(false);
  const [recentlyViewedClaims, setRecentlyViewedClaims] = useState<RecentlyViewedClaim[] | undefined>(undefined);

  const {getSignedInSentimentUser, isSignedIn} = useAuth();

  // const navigate = useNavigate();
  const signedInSentimentUser: SentimentUser | undefined = getSignedInSentimentUser();

  useEffect(() => {
    (async () => {
      // Don't fetch multiple at the same time, and only fetch if there's a signed-in user that can provide a JWT to
      // axios.
      if (!isFetchingRecentClaims.current && isSignedIn()) {
        isFetchingRecentClaims.current = true;
        // Fetch the RecentlyViewedClaims
        const signedInSentimentUserId = getSignedInSentimentUser()?.id;
        if (signedInSentimentUserId) {
          try {
            const recentlyViewedClaimMetaArr: RecentlyViewedClaimMeta[] = await fetchRecentlyViewedClaimMeta(
              signedInSentimentUserId
            );
            // Then -> Make a single call to firestore.
            const hydratedClaims: Array<RecentlyViewedClaim>
              = await fetchMultipleClaimsFromFirestore(recentlyViewedClaimMetaArr);
            // Then -> Push the results onto recentlyViewedClaims
            setRecentlyViewedClaims(hydratedClaims);
          } catch (error) {
            // Set to `undefined` to show a loader screen; set to [] to show welcome screen. In this csae, showing the
            // loader is probably appropriate since we can't get recent claims anyway.
            setRecentlyViewedClaims(undefined);
            // console.error(error);
          }
        }
        isFetchingRecentClaims.current = false;
      }
    })();
  }, [signedInSentimentUser]);  // <-- Only trigger if signedInSentimentUser changes.

  /**
   * Fetch the most recent claims for the signed in user. This function is only ever called under the correct conditions
   * (i.e., (a) a user is signed in that can provide a JWT to axios; (b) a different call to this function is not
   * currently in-flight).
   */
    // TODO: Fetch proper pagination
  const fetchRecentlyViewedClaimMeta =
      async (signedInSentimentUserId: string): Promise<Array<RecentlyViewedClaimMeta>> => {
        // NOTE: See doc above; the caller of this function guarantees necessary preconditions.
        console.debug("Fetching Recent Claims in progress.")
        const url: string = fetchRecentlyViewedClaimsUrl(signedInSentimentUserId);
        console.debug("Fetching RecentClaims from " + url)
        return sentimentAxios.get(url)
          .then((response: AxiosResponse<any, any> | void) => {
            if (response && response.data) {
              const axiosResponse = response as AxiosResponse;
              return (axiosResponse.data as Array<any>)
                .map(item => {
                  return {
                    claimId: item.claimId,
                    // This will be a string, so requires conversion...
                    lastViewedAt: Timestamp.fromDate(new Date(item.lastViewedAt)),
                  } as RecentlyViewedClaimMeta;
                });
            } else {
              if (isAxiosError(response)) {
                throw new Error("Whoops!");
              } else {
                return new Array<RecentlyViewedClaimMeta>();
              }
            }
          });
      };

  /**
   * Given {@link RecentlyViewedClaimMeta}, hydrate the actual claim information with data from Firestore.
   * @param recentlyViewedClaimMeta
   */
    // TODO: Store fetched claims in a small cache, e.g., 5 mins or localstorage to reduce reads.
  const fetchClaimFromFirestore =
      async (recentlyViewedClaimMeta: RecentlyViewedClaimMeta): Promise<(RecentlyViewedClaim | undefined)> => {
        const claimId: string = recentlyViewedClaimMeta.claimId;
        const docRef = doc(db, "claims", claimId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const claimWithIncludes: ClaimWithIncludes = docSnap.data() as ClaimWithIncludes;
          return {
            claim: {
              ...claimWithIncludes,
              data: {
                ...claimWithIncludes.data,
                id: claimId,
              }
            },
            lastViewedAt: recentlyViewedClaimMeta.lastViewedAt
          }
        } else {
          // docSnap.data() will be undefined in this case
          console.warn("No Firestore document with claimId=`%s`!", claimId);
          return undefined;
        }
      };

  /**
   * Given an array of {@link RecentlyViewedClaimMeta}, fetch each actual claim from firestore, in parallel.
   * @param recentlyViewedClaimMetas
   */
  const fetchMultipleClaimsFromFirestore =
    async (recentlyViewedClaimMetas: Array<RecentlyViewedClaimMeta>): Promise<Array<RecentlyViewedClaim>> => {
      // See https://stackoverflow.com/questions/69013319/
      // remove-undefined-resolved-promise-from-array-of-promise-in-typescript
      const results = await Promise.all(
        recentlyViewedClaimMetas.map((claimMeta: RecentlyViewedClaimMeta) => fetchClaimFromFirestore(claimMeta))
      );
      return results.filter((r): r is RecentlyViewedClaim => r !== undefined);
    };

  return (
    // Allow recentlyViewedClaims to be undefined. This is a signal to show the loading screen.
    <ClaimList claims={recentlyViewedClaims} listHeading={"Recently Viewed Claims"}/>);
};

export default RecentlyViewedClaimsView;