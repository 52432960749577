import {Navigate, Outlet, useLocation, useSearchParams} from "react-router-dom";
import React from "react";
import {useAuth} from "./AuthContext";
import {SLASH_SIGN_IN} from "../../model/url_constants";
import {prevLocationTracker} from "../../utils/location_utils";

const PrivateRoutes = () => {
  const {getSignedInSentimentUser} = useAuth();

  const location = useLocation();
  const [searchParams] = useSearchParams();

  prevLocationTracker.setPreviousLocationPath(location.pathname, searchParams.toString());

  // Redirect them to the /login page, but save the current location they were trying to go to when they were
  // redirected. This allows us to send them along to that page after they log in, which is a nicer user experience
  // than dropping them off on the home page. Note that when signedInSentimentUser is null, this will act like a
  // `false` value. Note that this only forwards to the /sign_in page for private routes (like an admin page).
  return getSignedInSentimentUser() ? <Outlet/> : <Navigate to={SLASH_SIGN_IN} replace/>
};

export default PrivateRoutes;